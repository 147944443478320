import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth';

@Injectable()
export class UserResolver {
	constructor(private _authService: AuthService) {}

	resolve(route: ActivatedRouteSnapshot) {
		return this._authService.checkUser();
	}
}
